<template>
    <div class="content_container online_contact">
		<div class="content">
			<div class="banner">
				<h1>온라인 상담</h1>
			</div>
			<section class="fill">
				<div class="flex_container">
					<div class="input_online type">
						<label>
							<span class="tag required">문의 유형<br>(민원신청)</span>
							<select v-model="type">
								<option selected>선택</option>
								<option>일반대출</option>
								<option>부동산 PF</option>
								<option>일반리스</option>
								<option>할부금융</option>
								<option>Mezzanine 투자</option>
								<option>신기술투자</option>
								<option>민원신청</option>
								<option>기타문의</option>
							</select>
						</label>
					</div>
				</div>
				<div class="flex_container">
					<div class="input_online">
						<label>
							<span class="tag required">E-mail</span>
							<input type="email" name="email" v-model="email" id="email"/>
						</label>
					</div>
					<div class="input_online">
						<label>
							<span class="tag required">연락처</span>
							<input type="tel" name="contact" maxlength="13" placeholder="핸드폰 번호" v-model="contact" />
						</label>
					</div>
				</div>
				<div class="flex_container">
					<div class="input_online">
						<label>
							<span class="tag required">이름</span>
							<input type="text" name="name" v-model="name" />
						</label>
					</div>
					<div class="input_online">
						<label>
							<span class="tag">회사명</span>
							<input type="text" name ="company" v-model="company" />
						</label>
					</div>
				</div>
				<div class="flex_container">
					<div class="input_online file">
						<label>
							<span class="tag">첨부파일</span>
							<button @click="selectFile">파일선택</button>
							<input type="file" name="file" id="file" @change="onFileSelected" style="display:none"/>
						</label>
					</div>
					<div class="input_online file" v-if="this.selectedFile">
						<div>
							<span class="tag">파일명</span>
							<div class="file_name">
								<p>{{this.selectedFile.name}}</p>
								<button @click="deleteSelectedFile()">x</button>
							</div>
						</div>
					</div>
				</div>
				<div class="flex_container">
					<div class="input_online title">
						<label>
							<span class="tag required">제목</span>
							<input type="text" name="title" v-model="title" />
						</label>
					</div>
				</div>
				<div class="flex_container">
					<div class="input_online con">
						<label>
							<span class="tag required">내용</span>
							<textarea name="content" v-model="content"></textarea>
						</label>
						<!-- <p>0/1000 byte</p> -->
						<div class="radiobox">
							<label>
								<input type="checkbox" id="privacy" />
								<router-link to="/privacy/privacypolicy" target="_blank" class="privacypolicy">
									개인 정보 수집 및 이용
								</router-link>
								에 동의합니다
							</label>
						</div>
					</div>
				</div>
				<div class="btn_wrapper btn_bottom_wrapper">
					<button type="button" class="btn_common" @click="send">상담하기</button>
				</div>
			</section>
        </div>
    </div>
</template>

<script>
import { phoneFormat } from '@/utils/phoneFormat'
import { emailCheck } from '@/utils/emailCheck'

export default {
    name: "OnlineContact",
	data(){
		return {
			type: "선택",		//필수
			email: "",			//필수
			company: "",
			contact: "",
			name: "",			//필수
			title: "",			//필수
			content: "",		//필수
			selectedFile: null,	//필수
		}
	},
	mixins : [ phoneFormat, emailCheck ],
	watch:{
		contact(phone){
			this.contact = this.phoneFormat(phone);
		}
	},
	methods: {
		selectFile(){
			document.getElementById('file').click();
		},
		onFileSelected(event){
			this.selectedFile = event.target.files[0];
		},
		deleteSelectedFile(){
			this.selectedFile = null;
		},
		send(){
			let isRequiredFill = (this.type !='선택' && this.name && this.email && this.title && this.content);
			if(!isRequiredFill){
				alert('필수 정보를 입력해 주세요.');
				const required = document.querySelectorAll('.required + *');
				for(let i=0;i<required.length;i++){
					if(required[i].value == "" || (required[i].value == "선택" && i==0)){
						required[i].focus();
						break;
					}
				}
			} else if(!this.emailCheck(this.email)){
				alert('올바른 이메일 형식을 입력해 주세요');
				document.getElementById('email').focus();
			} else if(!document.getElementById('privacy').checked){
				alert('개인 정보 수집 및 이용에 동의해 주세요.');
			} else {
				const formData = new FormData();
				formData.append("file", this.selectedFile);
				formData.append("type", this.type);
				formData.append("email", this.email);
				formData.append("company_name", this.company);
				formData.append("contact", this.contact);
				formData.append("name", this.name);
				formData.append("title", this.title);
				formData.append("content", this.content);
				this.axios.defaults.withCredentials = false;
				this.axios.post('/saveOnlinecontact', formData, {
					headers: {
						"Content-Type" : "multipart/form-data" 
					}
				}).then(res =>{
					console.log(res);
				});
				alert('상담신청이 완료되었습니다.');
				location.reload();
			}
		}
	}
}
</script>

